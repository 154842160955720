const APP_API_URL = process.env.REACT_APP_API_URL || '';

function send(url, options = {}) {
  return new Promise((resolve, reject) => {
    fetch(APP_API_URL + url, options)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.json());
        }

        const contentType = response.headers.get('content-type');
        if (!contentType || contentType.indexOf('application/json') === -1) {
          return response.text().then(text => reject(new Error(text)));
        }

        return response.json().then(err => reject(err.error));
      })
      .catch((error) => reject(error));
  });
}

const sendAsync = async (url, options = {}) => fetch(APP_API_URL + url, options);

function getJson(url) {
  return send(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
}

function getJsonAsync(url) {
  return sendAsync(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
}

function getJsonAuth(url, token) {
  return send(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
    },
  });
}

function postAuth(url, data, token) {
  return send(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
    },
    body: data,
  });
}

function postJson(url, data) {
  return send(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

function postJsonAuth(url, data, token) {
  return send(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

function putJsonAuth(url, token) {
  return send(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
    },
  });
}

function deleteJsonAuth(url, token) {
  return send(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
    },
  });
}

export { getJson };
export { getJsonAsync };
export { getJsonAuth };
export { deleteJsonAuth };
export { postAuth };
export { postJson };
export { postJsonAuth };
export { putJsonAuth };
